<template>
    <div>
        <z-header  :curActive="3"></z-header>
        <div class="container">
            <div class="title">
                <span>品质溯源</span>
                <span>QUALITY TRACEABILITY</span>
            </div>
            <div class="img">
                <img :src="require(`../../imgs/craft/`+qualityData.url)" alt="">
            </div>
            <div class="content">
                <span>{{qualityData.name}}</span>
                <i></i>
                <p v-for="(item,index) in qualityData.textList" :key="index">{{item}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import zHeader from "@/components/header.vue"
import cratyData from "./data"
export default {
    components:{zHeader},
    data(){
        return{
            qualityData:[]
        }
    },
    created(){
        this.qualityData = cratyData.qualityData[this.$route.query.id]
    }
}
</script>
<style scoped>
    .container{
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .title{
        display: flex;
        flex-direction: column;
    }
    .title>span:first-child{
        margin-top: 24px;
        font-size: 18px;
        line-height: 32px;
        font-weight: 600;
    }
    .title>span:last-child{
        color: #666;
        display: inline-block;
        font-size: 12px;
        border-bottom: 2px solid #eee;
        line-height: 28px;
        padding-bottom: 14px;
    }
    .img{
        width: 100%;
        height: 175px;
        border-radius: 5px;
        overflow: hidden;
        margin-top: 30px;
    }
    .img>img{
        width: 100%;
        height: 100%;
    }
    .content{
        display: flex;
        flex-direction: column;
        padding-bottom: 50px;
    }
    .content>span{
        margin-top: 12px;
        line-height: 50px;
        font-size: 16px;
        color: #bfa377;
    }
    .content>i{
        width: 80px;
        border-top: 2px solid #bfa377;
        margin-bottom: 10px;
    }
    .content>p{
        font-size: 14px;
        line-height: 26px;
    }
</style>