module.exports = {
    qualityData:[{
        name:"平台查询",
        url:"i2app.png",
        img:"b2.png",
        textList:[
            "（1）支持 PC 端溯源信息查询（主要包括：产品信息、 产品检测记录、生产信息、环境参数等）；",
            "（2）支持手机二维码扫描查询。"
        ]
    },{
        name:"采集平台",
        url:"i1app.png",
        img:"b1.png",
        textList:[
            "（1）溯源产品生产农户或企业信息采集及管理；",
            "（2）溯源生产基地信息采集及管理；",
            "（3）溯源生长过程的农事活动信息采集及管理；",
            "（4）溯源产品图片、包装、规格信息采集及管理；",
            "（5）溯源产品溯源码及地理标识采集并存档；",
            "（6）溯源产品生产过程、存储环境信息、视频及图片信息采集。"
        ]
    },{
        name:"数据服务平台",
        url:"i3app.png",
        img:"b3.png",
        textList:[
            "（1） 提供政府第三方平台接口查询服务；",
            "（2）提供其他交易平台溯源集成服务；",
            "（3）提供商家大宗交易追溯服务，可用于企业交易规模、信用、金融等信息评估服务。",
            "（4）提供第三方监管机构或政府部门监督服务，对农产品生产信息的审查，对不满足响应条件的可取消溯源服务；"
        ]
    }],
    teaData:[{
        name:"茶艺培训",
        text1:"2003年12月，经云南省劳动和社会保障厅批准，省茶叶研究所设立国家职业培训机构“西双版纳茶科所职业培训学校，并先后获批茶园工、茶叶加工工、茶艺师、评茶员四个工种初、中、高三个技术等级的培训资格。培训学校依托省茶叶研究所的场地、设备、技术及师资力量开展培训工作。",
        text2:"2009年由省茶叶研究所、勐海县茶叶产业办公室、勐海县劳动就业服务中心等有关单位联合组织的“勐海县首届民族茶艺师（初级）培训班”在省茶叶研究所职业培训站开班。",
        url:"a2.png"
    },{
        name:"茶艺展示",
        text1:"1993年4月首届“西双版纳国际茶王节”及2002年6月“中国普洱茶国际学术研讨会”期间，勐海茶厂、省茶叶研究所为中外来宾展示了傣族、哈尼族、布朗族等民族茶艺，受到中外来宾的一致好评。",
        text2:"2004年4月，省茶叶研究所组建了一支由15名高、中级茶艺师组成的民族茶艺表演队，并受西双版纳州茶业协会的委托，组织编排了《茶行古道传天香》《基诺族凉拌茶》《傣族香竹简茶》三个茶艺节目，代表西双版纳州到思茅参加第二届全国民族茶艺大赛，其中，展示茶马古道上主要民族饮用茶习俗的节目 《茶行古道传天香》荣获大赛唯一的一个金奖，基诺族 《凉拌茶》获优秀奖，傣族《香竹筒茶》获第11名。",
        url:"a3.png"
    }]
}